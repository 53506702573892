<template>
  <div>
    <ContactForm />
    <FAQSection />
  </div>
</template>

<script>
import ContactForm from '@/components/ui/landing/contact/ContactForm'
import FAQSection from '@/components/ui/landing/faq/FaqContact'

export default {
  components: {
    ContactForm,
    FAQSection
  },
  head: {
    title: {
      inner: 'Parfiniti',
      complement: 'Contact'
    }
  }
}
</script>
