<template>
  <v-sheet>
    <div class="d-flex flex-column flex-md-row">
      <div class="w-full w-md-half">
        <v-img
          src="/images/features/office-photo.jpg"
          :max-height="$vuetify.breakpoint.mdAndUp ? undefined : '300'"
          min-height="100%"
          alt=""
        ></v-img>
      </div>
      <div class="w-full w-md-half d-flex align-center">
        <div class="pa-6 pa-md-12">
          <h2 class="text-h3 text-lg-h2">Get in touch</h2>
          <div class="text-h6 text-lg-h5 mt-5">Interested in partnering with us? Shoot us a message below and we will schedule a demo as soon as we can.</div>
          <v-form ref="contactform" v-model="valid" class="mt-2">
            <v-text-field
              v-model="formData.name"
              :rules="[v => !!v || 'Name is required!']"
              label="Name"
              outlined
              solo
            ></v-text-field>
            <v-text-field
              v-model="formData.email"
              :rules="emailRules"
              label="Email"
              outlined
              solo
            ></v-text-field>
            <v-text-field
              v-model="formData.phone"
              :rules="phoneRules"
              label="Phone"
              outlined
              solo
            ></v-text-field>
            <v-textarea
              v-model="formData.message"
              :rules="[v => !!v || 'Message is required!']"
              label="Message"
              outlined
              solo
            ></v-textarea>
            <v-btn
              submit
              x-large
              color="accent"
              min-width="180"
              @click="submitContact()"
            >Send</v-btn>
            <v-alert
              v-if="success"
              outlined
              type="success"
              text
              class="mt-4"
            >
              {{ message || '' }}
            </v-alert>
            <v-alert
              v-if="error"
              outlined
              type="error"
              text
              class="mt-4"
            >
              {{ message || '' }}
            </v-alert>
          </v-form>
        </div>
      </div>
    </div>
  </v-sheet>
</template>
<script>
export default {
  data () {
    return {
      response: null,
      message: '',
      success: false,
      error: false,
      valid: false,
      formData: {
        name: '',
        email: '',
        phone: '',
        message: ''
      },
      emailRules: [
        (v) => !!v || 'Email is Required.',
        (v) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

          return pattern.test(v) || 'Invalid e-mail.'
        }
      ],
      phoneRules: [
        (v) => !!v || 'Phone is Required.',
        (v) => {
          const pattern = /^[0-9]*$/

          return pattern.test(v) || 'Invalid phone.'
        }
      ]
    }
  },
  methods: {
    submitContact () {
      this.$refs.contactform.validate()
      if (this.valid) {
        const requestOptions = {
          method: 'POST',
          //headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            name: this.formData.name,
            email: this.formData.email,
            phone: this.formData.phone,
            message: this.formData.message
          })
        }

        fetch('/.netlify/functions/send-contact-email', requestOptions)
          .then((res) => {
            console.log(res)
            if (res.status === 400) {
              this.error = true
              this.success = false
            }
            if (res.status === 200) {
              this.success = true
              this.error = false
              this.$refs.contactform.reset()
            }

            return res.json()
          })
          .then((data) => {
            if (data.message) {
              this.message = data.message
            }
            //console.log(data)
          })
          .catch((e) => {
            this.error = true
            //this.res = e.body.toString()
          })

      } // close IF FORM VALID
    } // End submitFunction
  }
}
</script>
