<template>
  <div>
    <Consulting />
    <SmartSolutions />
    <TechInvestments />
    <Security />
    <Web />
  </div>
</template>

<script>
import Web from '@/components/ui/landing/feature/WebServiceFeature'
import Security from '@/components/ui/landing/feature/SecurityServiceFeature'
import Consulting from '@/components/ui/landing/feature/ConsultServiceFeature'
import SmartSolutions from '@/components/ui/landing/feature/SmartSolutionsServiceFeature'
import TechInvestments from '@/components/ui/landing/feature/InvestmentsServiceFeature'

export default {
  components: {
    Web,
    Security,
    Consulting,
    SmartSolutions,
    TechInvestments
  },
  head: {
    title: {
      inner: 'Parfiniti',
      complement: 'Our Services'
    }
  }
}
</script>
