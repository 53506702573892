<template>
  <v-sheet>
    <v-container class="py-4 py-lg-8">
      <v-row>
        <v-col cols="12" md="6" order-md="1">
          <v-img src="/images/features/SEO_SVG.svg" contain class="rounded-lg" max-height="600"></v-img>
        </v-col>
        <v-col cols="12" md="6" order-md="2">
          <h2 class="text-h3 text-lg-h2 mt-0 mt-xl-10">Digital Marketing</h2>

          <!-- <div class="text-body-1 text-lg-h6 font-weight-bold mt-8 mt-xl-10">Lifetime Updates</div> -->
          <div class="text-body-1 text-lg-h6 mt-8 mt-xl-10">Together, we can configure your organization for transformative growth by maximizing your brand's purpose. Your customer's experience is the key to sustainable growth. With the ever-expanding explosion of platforms and tools, today's customer experiences have become fragmented and frustrating.</div>

          <!-- <div class="text-body-1 text-lg-h6 font-weight-bold mt-8 mt-xl-10">Premium Design</div>
          <div class="text-body-1 text-lg-h6">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem, vitae illo! Dolorum repudiandae impedit facilis, aperiam debitis quisquam excepturi reiciendis enim perspiciatis asperiores quibusdam et adipisci, delectus deserunt distinctio aliquam.</div> -->
          <v-list dense class="mt-4">
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script>
export default {
  data: () => ({
    items: [
      { text: 'CRM Integration', icon: 'mdi-card-account-mail' },
      { text: 'SEO Reporting', icon: 'mdi-magnify' },
      { text: 'Email Automation', icon: 'mdi-email-check-outline' },
      { text: 'Content Marketing', icon: 'mdi-book-open-page-variant' },
      { text: 'PPC Advertising', icon: 'mdi-google-ads' },
      { text: 'Media Asset Creation', icon: 'mdi-video-image' }
    ]
  })
}
</script>
