<template>
  <v-sheet>
    <v-container class="py-4 py-lg-8">
      <v-row>
        <v-col cols="12" md="6" order-sm="1">
          <v-img src="/images/features/Data_analysis_SVG.svg" contain class="rounded-lg" max-height="600"></v-img>
        </v-col>
        <v-col cols="12" md="6" order-sm="2">
          <h2 class="text-h3 text-lg-h2 mt-0 mt-xl-10">Security Strategy</h2>

          <div class="text-body-1 text-lg-h6 mt-8 mt-xl-10">In order for our clients to better manage cyber risks with business priorities at Parfiniti, we help to design and implement transformational enterprise security programs, organizational constructs, and capabilities: </div>
          <v-list dense class="mt-4">
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script>
export default {
  data: () => ({
    items: [
      { text: 'Integrated risk management/governace, and compliance', icon: 'mdi-database-alert' },
      { text: 'Third-party cyber risk', icon: 'mdi-badge-account-alert-outline' },
      { text: 'Insider threat programs', icon: 'mdi-shield-alert' },
      { text: 'Cyber assessments, frameworks, and benchmarking', icon: 'mdi-shield-search' },
      { text: 'Cyber strategy and program transformation', icon: 'mdi-file-swap-outline' },
      { text: 'Cyber metrics, reporting, and risk quantification', icon: 'mdi-file-chart' },
      { text: 'Cyber awareness, board reporting, and training', icon: 'mdi-sitemap' }
    ]
  })
}
</script>
<style scoped>
  div.v-list-item__title {
    white-space: unset;
  }
</style>
