<template>
  <v-sheet color="transparent" class="hero-bg">
    <v-container class="py-2 py-md-6">
      <v-row>
        <v-col cols="12" md="8" lg="10">
          <h1 class="text-h4 text-sm-h3 secondary--text text-lg-h1 mb-xl-4 mt-sm-4"><span class="accent--text">Empowering</span> For The Future</h1>
          <v-btn
            large
            color="accent"
            class="my-9"
            to="/services"
          >Explore More</v-btn>
        </v-col>
        <!-- <v-col cols="12" md="6" lg="5" class="d-none d-md-block">
          <v-img
            class="rounded-lg mt-2"
            max-height="360"
            contain
            :src="require('@/assets/images/hero4.svg')"
          >
          </v-img>
        </v-col> -->
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>

export default {
  components: {
  }
}
</script>
<style scoped>
  .hero-bg {
    background-image: url('/images/homepage-herobg.jpg');
    background-position: center;
    background-size: cover;
  }
</style>
