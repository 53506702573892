<template>
  <v-sheet color="transparent">
    <v-container class="py-4 py-lg-8">
      <v-row>
        <v-col cols="12" lg="8">
          <h1>Cookie Notice of Parfiniti</h1>
          <h2>Introduction</h2>
          <p>This Cookie Notice explains how Parfiniti, Inc. (collectively, "Parfiniti", or “we” “us”, and “ours”) use cookies and similar technologies (for example pixels and web beacons) when you visit our websites ("Websites"). This Policy explains what these technologies are and why we use them, as well as your rights to control our use of them. Where personal information is collected by Parfiniti Inc, such collection is covered by our Privacy Notice, available <router-link to="/privacy-policy">here</router-link>.</p>

          <h2>What are Cookies?</h2>
          <p>
            Cookies are small data files that are placed on your computer or mobile device when you visit a website in order to remember information about you, such as your language preference or login information. Cookies are widely used by website owners in order to make their websites work, or to work more efficiently, as well as to provide reporting information. For examples, cookies allow us to collect information such as browser type, time spent on our Websites, pages visited, registrations, submissions, information requests, language preferences and other traffic data. Cookies set by the website owner (in this case, Parfiniti, Inc.) are called "first-party cookies". Cookies set by parties other than the website owner are called "third-party cookies". Third-party cookies enable third-party features or functionality to be provided on or through the website (e.g. like advertising, interactive content and analytics). The parties that set these third-party cookies can recognize your computer both when it visits the website in question and also when it visits certain other websites.
          </p>

          <h2>Why do we Use Cookies and which Cookies do we Use?</h2>
          <p>We use first-party and third-party cookies and other tracking technologies for the following purposes:</p>
          <ul>
            <li>Recognize your device;</li>
            <li>Facilitate navigation on our Websites;</li>
            <li>Display information more effectively;</li>
            <li>Personalize your experience;</li>
            <li>Assist in registration to our events, login and your ability to provide feedback;</li>
            <li>Analyze your use of our Websites;</li>
            <li>Assist with our promotional and marketing efforts, including behavioral advertising; and</li>
            <li>Assist with our information security efforts related to the Websites.</li>
          </ul>
          <p>More specifically, we use the following types of cookies:</p>
          <ul>
            <li><strong>Strictly Necessary Cookies:</strong> These cookies are necessary for the Websites to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but then some parts of the Websites will not work. These cookies do not store any personally identifiable information.</li>
            <li><strong>Performance Cookies:</strong> These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our Websites. They help us know which pages are the most and least popular and see how visitors move around the Websites. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know when you have visited our Websites and will not be able to monitor its performance.</li>
            <li><strong>Functional Cookies:</strong> These cookies enable the website to provide enhanced functionality and personalization. They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function properly.</li>
            <li><strong>Targeting Cookies:</strong> These cookies may be set through our Websites by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites. They do not store directly personal information but are based on uniquely identifying your browser and internet device. If you do not allow these cookies, you will experience less targeted advertising.</li>
          </ul>
          <p>we use Google Analytics, a web analytics service provided by Google, Inc. Google Analytics uses cookies or other tracking technologies to help us analyze how users interact with our Websites and services, compile reports on their activity, and provide other services related to their activity and usage. The technologies used by Google may collect information such as your IP address, time of visit, whether you are a returning visitor, and any referring website. The technologies used by Google Analytics do not gather information that personally identifies you. The information generated by Google Analytics will be transmitted to and stored by Google and will be subject to Google’s <a href="https://policies.google.com/privacy?hl=en" target="_blank">privacy policies</a>. To learn more about Google’s partner services and to learn how to opt-out of tracking of analytics by Google, <a href="https://policies.google.com/technologies/partner-sites">click here</a>.</p>

          <h2>How Can I Control Cookies?</h2>
          <p>Browser Settings</p>
          <p>Cookies can also be blocked by changing your internet browser settings to refuse all or some cookies. If you choose to block all cookies (including Strictly Necessary Cookies), you may not be able to access all or parts of the Websites. You can find out more about cookies and how to manage them by visiting <a href="https://www.AboutCookies.org">www.AboutCookies.org</a>.</p>
          <p>Advertising Industry Resources</p>
          <p>You can understand which entities have currently enabled cookies for your browser or mobile device and how to opt out of some of those cookies by accessing the <a href="https://www.networkadvertising.org/join-nai">Network Advertising Initiative’s website</a> or the <a href="https://digitaladvertisingalliance.org/">Digital Advertising Alliance’s website</a>. For more information on mobile specific opt-out choices, visit the <a href="https://www.networkadvertising.org/mobile-choices">Network Advertising Initiative’s Mobile Choices Website</a>. Please note that these opt-out mechanisms are specific to the device or browser on which they are exercised. Therefore, you will need to opt out on every browser and device that you use.</p>
          <p>“Do Not Track” Signals</p>
          <p>Some internet browsers incorporate a “Do Not Track” feature that signals to websites you visit that you do not want to have your online activity tracked. Given that there is not a uniform way that browsers communicate the “Do Not Track” signal, our Websites do not currently interpret, respond to or alter its practices when it receives “Do Not Track” signals.</p>

          <h2>How Often Will You Update this Cookie Notice?</h2>
          <p>We may update this Cookie Notice from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal or regulatory reasons. Please therefore re-visit this Cookie Notice regularly to stay informed about our use of cookies and related technologies.</p>

          <h2>Effective Date of Notice</h2>
          <p>This Cookie Notice was last updated on Janurary 1, 2021.</p>

          <h2>Where Can I Get Further Information?</h2>
          <p>If you have any questions about our use of cookies or other technologies, please email us at contact@parfiniti.com.</p>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>

export default {
  components: {},
  head: {
    title: {
      inner: 'Parfiniti',
      complement: 'Cookie Policy'
    }
  }
}
</script>
