<template>
  <v-sheet>
    <v-container class="py-4 py-lg-8">
      <v-row>
        <v-col cols="12" md="6" order-md="1">
          <v-img src="/images/features/security_SVG.svg" contain class="rounded-lg" max-height="600"></v-img>
        </v-col>
        <v-col cols="12" md="6" order-md="2">
          <h2 class="text-h3 text-lg-h2 mt-0 mt-xl-10">Identity Security</h2>
          <div class="text-body-1 text-lg-h6 mt-8 mt-xl-10">Via leveraging identity methodologies and digital identity platforms Parfiniti helps clients manage which employees, partners, suppliers, customers, consumers, and public users access sensitive business applications and data:</div>
          <v-list dense class="mt-4">
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script>
export default {
  data: () => ({
    items: [
      { text: 'Identity strategy', icon: 'mdi-shield-account' },
      { text: 'Identity governance and administration', icon: 'mdi-account-edit' },
      { text: 'Advanced authentication', icon: 'mdi-card-account-details' },
      { text: 'Privileged access management', icon: 'mdi-account-check' },
      { text: 'Identity analytics', icon: 'mdi-chart-donut' },
      { text: 'Consumer identity', icon: 'mdi-face-agent' },
      { text: 'Directory services', icon: 'mdi-clipboard-list-outline' },
      { text: 'Cloud and hosted identity solutions', icon: 'mdi-cloud-check-outline' },
      { text: 'Identity operations and managed services', icon: 'mdi-account-outline' }
    ]
  })
}
</script>
<style scoped>
  div.v-list-item__title {
    white-space: unset;
  }
</style>
