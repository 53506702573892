<template>
  <v-sheet color="transparent">
    <v-container class="py-4 py-lg-8">
      <v-row>
        <v-col cols="12" lg="6" xl="4">
          <v-sheet elevation="6" class="pa-2" rounded>
            <v-carousel
              v-model="model"
              :show-arrows-on-hover="true"
              cycle
              height="400"
              hide-delimiter-background
              :light="!$vuetify.theme.isDark"
            >
              <v-carousel-item v-for="(item, i) in carousel" :key="i">
                <v-sheet>
                  <v-responsive max-width="360" class="mx-auto text-center">
                    <div class="text-h4 mt-8 text-uppercase">{{ item.title }}</div>
                    <div class="text-body-1 mt-5">{{ item.text }}</div>
                    <!-- <div class="mt-4">
                      <router-link :to="item.link.to" class="text-decoration-none font-weight-bold text-h6">{{ item.link.title }}</router-link>
                    </div> -->
                  </v-responsive>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
          </v-sheet>
        </v-col>
        <v-col cols="12" lg="6" offset-xl="2" xl="6">
          <h2 class="text-h3 text-lg-h2 mt-0 mt-lg-3 mt-xl-10">Industries</h2>
          <div class="text-h6 text-lg-h5 mt-6">Parfiniti provides enterprises and organizations with the right solutions and services to grow your business utilizing our industry expertise.</div>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  data: () => ({
    model: 0,
    carousel: [{
      title: 'Consumer',
      text: 'Our leaders work with global brands to help them create winning strategies in the various sectors. Our mission is to help you build trust with your clients.',
      link: {
        title: 'Learn More',
        to: '#'
      }
    }, {
      title: 'Life Sciences & Health Care',
      text: 'Our team of professionals can help guide life sciences and health care companies in navigating the complexities of the US and global health care systems.',
      link: {
        title: 'Learn More',
        to: '#'
      }
    }, {
      title: 'Energy and Resource Industrials',
      text: 'Our specialists provude broad, integrated solutions to the Oil & Gas; Power & Utlities; Industrial & Construction sectors.',
      link: {
        title: 'Learn More',
        to: '#'
      }
    }, {
      title: 'Financial Services',
      text: 'Industry specialists provide broad, integrated solutions to the Banking and Capital Markets, Insurance, and Investment Management sectors.',
      link: {
        title: 'Learn More',
        to: '#'
      }
    }, {
      title: 'Technology & Telecom',
      text: 'We help brands bloom in a digital world. Our Technology and Telecommunications practice brings together highly respected groups of specialists that helped shape the landscape.',
      link: {
        title: 'Learn More',
        to: '#'
      }
    }, {
      title: 'Government & Public Services',
      text: 'Our leaders can bring a fresh viewpoint to help you anticipate disruption, reimagine the possible, and fulfill your mission promise.',
      link: {
        title: 'Learn More',
        to: '#'
      }
    }]
  })
}
</script>
