<template>
  <v-sheet>
    <v-container class="py-4 py-lg-8">
      <div class="text-center">
        <div class="text-uppercase font-weight-bold body-2 primary--text mb-2">Support</div>
        <h2 class="text-h3 text-lg-h2">Frequently Asked Questions</h2>
        <v-responsive max-width="1200" class="mx-auto">
          <div class="text-h6 text-lg-h5 mt-4">Have a question? You may find your answer below. If not, feel free to reach out to us using the contact form above.</div>
        </v-responsive>
      </div>

      <v-expansion-panels
        v-model="panel"
        multiple
        class="mt-8"
      >
        <v-expansion-panel
          v-for="(item, i) in faq"
          :key="i"
        >
          <v-expansion-panel-header class="text-h5">
            {{ item.question }}
          </v-expansion-panel-header>
          <v-expansion-panel-content class="text-h6 secondary--text">
            {{ item.answer }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      panel: [0],
      faq: [{
        question: 'Is your operation in-house or outsourced solutions?',
        answer: 'At Parfiniti we use a mixed hybrid approach. We assess your business needs in order to put together a strategy for cost effective solutions. This can be a mix of in-house and outsourcing efforts.'
      }, {
        question: 'I have a startup company and need hardware and network infrustructure, how can you help?',
        answer: 'We have many solutions when it comes to IT and networking infrustructure for your business of any type.'
      }, {
        question: 'What are Deal Flows at Parfiniti?',
        answer: 'Deal Flow is the stream of business proposals, pitches and investment offers that venture capital, angel and private equity investors identify and engage with on a daily basis.'
      }]
    }
  }
}
</script>
