<template>
  <v-sheet>
    <v-container class="py-4 py-lg-8">
      <div class="text-center">
        <div class="text-uppercase font-weight-bold body-1 accent--text mb-2">Business | Potential | Maximized</div>
        <h2 class="text-h3 text-lg-h2">Accelerate your Business Values</h2>
        <v-responsive max-width="1200" class="mx-auto">
          <div class="text-h6 text-lg-h5 mt-4">Parfiniti provides services to help customers significantly improve their customer experience, growth, and retention.</div>
        </v-responsive>
      </div>
      <v-row class="mt-4">
        <v-col v-for="(item, i) in features" :key="i" cols="12" md="6">
          <div class="d-flex">
            <div class="mr-2">
              <v-sheet color="surface" rounded class="pa-2" elevation="10">
                <v-icon>{{ item.icon }}</v-icon>
              </v-sheet>
            </div>
            <div>
              <div class="text-h5"><router-link class="accent--text" :to="item.link">{{ item.title }}</router-link></div>
              <div class="text-h6 mt-1">{{ item.description }}</div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      features: [{
        icon: 'mdi-account-tie',
        title: 'Enterprise Consulting',
        link: '/services/consulting',
        description: 'Web & Application Services, Infrastructure, Business Strategy, Staffing, M&A, and Cloud Services are just some of the many consulting services Parfiniti provides.'
      }, {
        icon: 'mdi-web',
        link: '/services/smart-solutions',
        title: 'Smart Solutions',
        description: 'Smart City Development, IoT, Blockchain, and Low Energy Sensor Networks are a few of the emerging technologies that are empowering communities and organizations for the future.'
      }, {
        icon: 'mdi-security',
        link: '/services/security',
        title: 'Information Security',
        description: 'Are you putting your business at risk by not implementing cyber security best practices? Our team of professionals will be able to help.'
      }, {
        icon: 'mdi-finance',
        link: '/services',
        title: 'Technology Investments',
        description: 'Helping Venture Capital firms find companies and Startups for their investment needs. Connecting Startup entrepreneur with startup accelerators.'
      }]
    }
  }
}
</script>
