<template>
  <div>
    <Header />
    <Feature />
    <Fundamentals />
    <IotFeature />
    <InfoComm />
    <AIFeature />
    <BlockchainFeature />
    <CallToAction />
  </div>
</template>

<script>
import Header from '@/components/ui/landing/feature/SmartSolutionsHeader'
import Feature from '@/components/ui/landing/feature/SmartCityFeature'
import Fundamentals from '@/components/ui/landing/feature/SmartCityFundamentals'
import IotFeature from '@/components/ui/landing/feature/IotFeature'
import InfoComm from '@/components/ui/landing/feature/InfoAndCommFeature'
import AIFeature from '@/components/ui/landing/feature/AiMachineLearningFeature'
import BlockchainFeature from '@/components/ui/landing/feature/BlockchainFeature'
import CallToAction from '@/components/ui/landing/cta/CallToAction4'

export default {
  components: {
    Header,
    Feature,
    IotFeature,
    InfoComm,
    AIFeature,
    Fundamentals,
    BlockchainFeature,
    CallToAction
  },
  head: {
    title: {
      inner: 'Parfiniti',
      complement: 'Smart Solutions'
    }
  }
}
</script>
