<template>
  <v-sheet>
    <v-container class="py-4 py-lg-8">
      <v-row>
        <v-col cols="12" md="4">
          <h2 class="text-h3 text-lg-h2">Fundamentals of a Smart City</h2>
        </v-col>
        <v-col cols="12" md="8" class="pa-md-0">
          <v-row>
            <v-col v-for="(item, i) in features" :key="i" cols="12" md="6">
              <div class="d-flex">
                <div class="mr-2">
                  <v-sheet color="surface" rounded class="pa-2">
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-sheet>
                </div>
                <div>
                  <div class="text-h5">{{ item.title }}</div>
                  <div class="text-h6 mt-1">{{ item.description }}</div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      features: [{
        icon: 'mdi-nature-people',
        title: 'Quality of Life',
        description: 'Successful smart cities will drive innovations in economy, environment and energy, government and education, living and health, safety and security, and mobility.'
      }, {
        icon: 'mdi-cash-multiple',
        title: 'Economic Competitiveness',
        description: 'Smart cities help drive economic growth via important centers for trade and commerce, business-friendliness, ensures jobs and tax revenues create a healthy economic platform.'
      }, {
        icon: 'mdi-factory',
        title: 'Sustainability',
        description: 'Protecting the environment and promoting sustainable consumption of natural resources is part of the overall smart city vision.'
      }]
    }
  }
}
</script>
