<template>
  <v-sheet>
    <v-container class="py-4 py-lg-8">
      <v-row>
        <v-col cols="12" md="6" order-md="2">
          <v-img src="/images/features/Cloud_database_SVG.svg" contain class="rounded-lg" max-height="600"></v-img>
        </v-col>
        <v-col cols="12" md="6" order-md="1">
          <h2 class="text-h3 text-lg-h2 mt-0 mt-xl-10">Cloud Security</h2>

          <div class="text-body-1 text-lg-h6 mt-8 mt-xl-10">Through business risk, regulatory, technology, and cyber lens. Parfiniti offers a distinct vision to securing the cloud environment by providing a broad set of cloud transformation services:</div>
          <v-list dense class="mt-4">
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script>
export default {
  data: () => ({
    items: [
      { text: 'Application modernization and migration', icon: 'mdi-cloud-upload' },
      { text: 'Orchestration and automation ', icon: 'mdi-clipboard-flow' },
      { text: 'Cloud managed services ', icon: 'mdi-cloud-tags' },
      { text: 'Secure landing zones', icon: 'mdi-cloud-lock-outline' },
      { text: 'DevSecOps', icon: 'mdi-cloud-braces' },
      { text: 'Cloud security analytics', icon: 'mdi-chart-timeline' }
    ]
  })
}
</script>
