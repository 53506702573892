<template>
  <v-sheet>
    <v-container class="py-4 py-lg-8">
      <v-row>
        <v-col cols="12" md="6" order-sm="1">
          <v-img src="/images/features/Coding_SVG.svg" contain class="rounded-lg" max-height="600"></v-img>
        </v-col>
        <v-col cols="12" md="6" order-sm="2">
          <h2 class="text-h3 text-lg-h2 mt-0 mt-xl-10">Development and Design</h2>

          <div class="text-body-1 text-lg-h6 mt-8 mt-xl-10">Parfiniti combines the power of modern platforms with our innovation and industry capabilities in order to offer end-to-end application services. Whether it's modernizing an existing app, or development of new applications, we cover all aspects of the development lifecycle. </div>

          <!-- <div class="text-body-1 text-lg-h6 font-weight-bold mt-8 mt-xl-10">Premium Design</div>
          <div class="text-body-1 text-lg-h6">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem, vitae illo! Dolorum repudiandae impedit facilis, aperiam debitis quisquam excepturi reiciendis enim perspiciatis asperiores quibusdam et adipisci, delectus deserunt distinctio aliquam.</div> -->
          <v-list dense class="mt-4">
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script>
export default {
  data: () => ({
    items: [
      { text: 'Software Engineering', icon: 'mdi-console' },
      { text: 'Web Development', icon: 'mdi-file-code' },
      { text: 'Data Management', icon: 'mdi-database-search' },
      { text: 'Process Automation', icon: 'mdi-robot-excited' },
      { text: 'Application Architecture', icon: 'mdi-sitemap' },
      { text: 'MVP Design', icon: 'mdi-pencil-ruler' }
    ]
  })
}
</script>
