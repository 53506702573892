<template>
  <v-sheet>
    <v-container class="py-4 py-lg-8">
      <v-row>
        <v-col cols="12" md="6" order-md="1">
          <v-img src="/images/features/app_development_SVG.svg" contain class="rounded-lg" max-height="600"></v-img>
        </v-col>
        <v-col cols="12" md="6" order-md="2">
          <h2 class="text-h3 text-lg-h2 mt-0 mt-xl-10">Application Security</h2>
          <div class="text-body-1 text-lg-h6 mt-8 mt-xl-10">In order to enable the design, build, and deployment of secure applications at Parfiniti, we integrate cyber security and compliance activities throughout the ERP transformation and SDLC (software development lifecycle):</div>
          <v-list dense class="mt-4">
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script>
export default {
  data: () => ({
    items: [
      { text: 'Concept and requirements', icon: 'mdi-pencil-ruler' },
      { text: 'Maintenance and retirement', icon: 'mdi-cog-refresh' },
      { text: 'DevSecOps', icon: 'mdi-console-line' },
      { text: 'Design and Development', icon: 'mdi-developer-board' },
      { text: 'Verification and authentication', icon: 'mdi-shield-check' },
      { text: 'Production', icon: 'mdi-server-security' }
    ]
  })
}
</script>
<style scoped>
  div.v-list-item__title {
    white-space: unset;
  }
</style>
