<template>
  <v-sheet>
    <v-container class="py-4 py-lg-8">
      <v-row>
        <v-col cols="12" md="6" order-md="2">
          <v-img src="/images/features/Business_SVG.svg" contain class="rounded-lg" max-height="600"></v-img>
        </v-col>
        <v-col cols="12" md="6" order-md="1">
          <h2 class="text-h3 text-lg-h2 mt-0 mt-xl-10">Analytics and Data Visualization</h2>

          <div class="text-body-1 text-lg-h6 mt-8 mt-xl-10">Our Analytics team can architect strategies for your data transformation and value creation. Through the power of technology we create bold visions utilizing digital innovation to unlock your business value.</div>

          <!-- <div class="text-body-1 text-lg-h6 font-weight-bold mt-8 mt-xl-10">Premium Design</div>
          <div class="text-body-1 text-lg-h6">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem, vitae illo! Dolorum repudiandae impedit facilis, aperiam debitis quisquam excepturi reiciendis enim perspiciatis asperiores quibusdam et adipisci, delectus deserunt distinctio aliquam.</div> -->
          <v-list dense class="mt-4">
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script>
export default {
  data: () => ({
    items: [
      { text: 'Web Analytics Integration', icon: 'mdi-google-analytics' },
      { text: 'BI / Data Visualization', icon: 'mdi-chart-pie' },
      { text: 'Enterprise Tag Management', icon: 'mdi-tag-multiple' },
      { text: 'Data Layer Architecture', icon: 'mdi-code-json' },
      { text: 'A/B Experimentation', icon: 'mdi-ab-testing' }
    ]
  })
}
</script>
