<template>
  <div>
    <ConsultingHero />
    <CallToAction />
  </div>
</template>

<script>
import ConsultingHero from '@/components/ui/landing/feature/ConsultingServicesHero'
import CallToAction from '@/components/ui/landing/cta/CallToAction4'

export default {
  components: {
    ConsultingHero,
    CallToAction
  },
  head: {
    title: {
      inner: 'Parfiniti',
      complement: 'Consulting Services'
    }
  }
}
</script>
