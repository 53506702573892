<template>
  <div>
    <Feature />
    <CallToAction />
  </div>
</template>

<script>
import Feature from '@/components/ui/landing/feature/AboutFeature'
import CallToAction from '@/components/ui/landing/cta/CallToAction4'

export default {
  components: {
    Feature,
    CallToAction
  },
  head: {
    title: {
      inner: 'Parfiniti',
      complement: 'About Us'
    }
  }
}
</script>
