<template>
  <v-sheet color="transparent">
    <v-container class="py-4 py-lg-8">
      <div class="text-center">
        <div class="text-uppercase font-weight-bold body-2 primary--text mb-2">Consulting Services</div>
        <h2 class="text-h3 text-lg-h2">Let us help build your business ecosystem</h2>
        <v-responsive max-width="1200" class="mx-auto">
          <div class="text-h6 text-lg-h5 mt-4">Technology will never stop evolving. Will you watch the world change around you? Or take the leading charge? Parfiniti can help you devise a strategy to discover the right technology for your business.</div>
        </v-responsive>
      </div>
      <v-row class="mt-6">
        <v-col
          v-for="(item, i) in features"
          :key="i"
          cols="12"
          md="6"
        >
          <v-card class="py-4 px-2">
            <div class="d-flex">
              <div class="mr-2">
                <v-sheet color="secondary" rounded class="pa-2" dark>
                  <v-icon large>{{ item.icon }}</v-icon>
                </v-sheet>
              </div>
              <div>
                <div class="text-h5 font-weight-bold">{{ item.title }}</div>
                <div class="font-weight-regular mt-1">{{ item.description }}</div>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      features: [{
        icon: 'mdi-city',
        title: 'Smart City Consulting Services',
        description: 'Transforming Cities, Transports, and Public Infrastructure to improve the quality of life through enhanced safety, empowering diverse lifestyles, and increased environment sustainability.'
      }, {
        icon: 'mdi-server-network',
        title: 'Application Services',
        description: 'From the development of new applications, through modernization, management and maintenance, we cover all stages of the application lifecycle.'
      }, {
        icon: 'mdi-strategy',
        title: 'Business Strategy',
        description: 'We help our clients drive growth by shaping their business strategies through a combination of advanced analytics, industry expertise and human-centered approach.'
      }, {
        icon: 'mdi-domain',
        title: 'Infrastructure',
        description: 'Your business can only perform as well as the foundation of your business. We help assist in optimizing your design and implementation of cloud ready infrastructure.'
      }, {
        icon: 'mdi-cart',
        title: 'Web and Ecommerce',
        description: 'We help our clients in creating stunning digital experiences that modernize core technology that optimize and automate operations in order to fuel digital growth, capitalize and adopt new technologies.'
      }, {
        icon: 'mdi-link-variant',
        title: 'Supply Chain Management',
        description: 'Today supply chains are under more pressure than ever. Our solution creates a supply chain that operates with higher speed, sustainability, efficiency with a foundation of transparency and trust.'
      }, {
        icon: 'mdi-apple-icloud',
        title: 'Cloud Services',
        description: 'Cloud services are an urgent need for almost any industry. Today\'s companies have to scale and operate at extreme speeds with partners, clients, and employees.'
      }, {
        icon: 'mdi-account-group',
        title: 'Staffing',
        description: 'We provide world class IT and professional recruiting services in helping you proactively achieve your goals.'
      }, {
        icon: 'mdi-sitemap',
        title: 'Business Process Outsourcing',
        description: 'Parfiniti employs exceptional people in our global BPO centers in North America, EMEA, and Asia. We apply intelligence, innovation and deep industry experience to drive business value and growth.'
      }, {
        icon: 'mdi-call-merge',
        title: 'Mergers and Acquisitions',
        description: 'Helping our clients find ways to seamlessly create value-filled transactions such as M&A strategy, separations, integrations, joint ventures, learning and building capabilities.'
      }]
    }
  }
}
</script>
