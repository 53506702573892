<template>
  <div>
    <DesignAndDev />
    <AnalyticsBi />
    <DigitalMarketing />
    <CallToAction />
  </div>
</template>

<script>
import DesignAndDev from '@/components/ui/landing/feature/DesignAndDev'
import AnalyticsBi from '@/components/ui/landing/feature/AnalyticsBi'
import DigitalMarketing from '@/components/ui/landing/feature/DigitalMarketing'
import CallToAction from '@/components/ui/landing/cta/CallToAction4'

export default {
  components: {
    DesignAndDev,
    AnalyticsBi,
    DigitalMarketing,
    CallToAction
  },
  head: {
    title: {
      inner: 'Parfiniti',
      complement: 'Web Services'
    }
  }
}
</script>
