<template>
  <v-sheet>
    <v-container class="py-4 py-lg-8">
      <v-row>
        <v-col cols="12" md="6" order-md="2">
          <v-img src="/images/features/Financial_insurance_SVG.svg" contain class="rounded-lg" max-height="600"></v-img>
        </v-col>
        <v-col cols="12" md="6" order-md="1">
          <h2 class="text-h3 text-lg-h2 mt-0 mt-xl-10">Data &#38; Privacy</h2>

          <div class="text-body-1 text-lg-h6 mt-8 mt-xl-10">Assisting our clients in their data discovery, collection, processing, sharing, protecting, archiving, and deletion. At Parfiniti we take pride in helping organizations build sustainable, scalable programs designed around strategic principles that address operational and regulatory requirements which enables data privacy and protection across the data life cycle:</div>
          <v-list dense class="mt-4">
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script>
export default {
  data: () => ({
    items: [
      { text: 'Privacy', icon: 'mdi-shield-lock' },
      { text: 'Protection', icon: 'mdi-shield-plus-outline' },
      { text: 'Strategy', icon: 'mdi-strategy' },
      { text: 'Reporting and validation', icon: 'mdi-file-chart' },
      { text: 'Architecture', icon: 'mdi-ruler-square' }
    ]
  })
}
</script>
<style scoped>
  div.v-list-item__title {
    white-space: unset;
  }
</style>
