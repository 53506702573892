<template>
  <div>
    <Hero></Hero>
    <Feature1></Feature1>
    <CallToAction/>
    <Feature2></Feature2>
    <Logos></Logos>
  </div>
</template>

<script>
import Hero from '@/components/ui/landing/hero/HomeHero'
import Feature1 from '@/components/ui/landing/feature/HomePageFeature'
import Feature2 from '@/components/ui/landing/feature/HomePageFeature2'
import CallToAction from '@/components/ui/landing/cta/CallToAction4'
import Logos from '@/components/ui/landing/logos/BrandLogos'

export default {
  components: {
    Hero,
    CallToAction,
    Feature1,
    Feature2,
    Logos
  },
  head: {
    title: {
      inner: 'Parfiniti'
    }
  }
}
</script>
