<template>
  <v-sheet>
    <v-container class="py-4 py-lg-8">
      <v-row>
        <v-col cols="12" md="6" order-md="2">
          <v-img src="/images/features/Web_hosting_SVG.svg" contain class="rounded-lg" max-height="600"></v-img>
        </v-col>
        <v-col cols="12" md="6" order-md="1">
          <h2 class="text-h3 text-lg-h2 mt-0 mt-xl-10">Infrastructure Security</h2>

          <div class="text-body-1 text-lg-h6 mt-8 mt-xl-10">Parfiniti can assess, strategize, architect, implement, and manage solutions to help clients manage risks and exposure. Today's business pushes to become more agile, reduce cost, and simplify IT which in-turn disrupts traditional infrastructure. As technology continues to evolve business and the threats to business operations become increasingly complex, businesses need to transform how they secure their enterprises:</div>
          <v-list dense class="mt-4">
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script>
export default {
  data: () => ({
    items: [
      { text: 'Core infrastructure security', icon: 'mdi-shield-home' },
      { text: 'Asset management', icon: 'mdi-toolbox' },
      { text: 'Attack surface management', icon: 'mdi-table-of-contents' },
      { text: 'Cloud infrastructure security', icon: 'mdi-cloud-lock' },
      { text: 'Mobile and endpoint security', icon: 'mdi-shield-link-variant' },
      { text: 'Technical resilience', icon: 'mdi-shield-lock' }
    ]
  })
}
</script>
