<template>
  <div>
    <Strategy />
    <DataPrivacy />
    <AppSecurity />
    <Infrastructure />
    <Identity />
    <Cloud />
    <Industries />
    <CallToAction />
  </div>
</template>

<script>
import Strategy from '@/components/ui/landing/feature/SecurityStrategy'
import DataPrivacy from '@/components/ui/landing/feature/SecurityDataAndPrivacy'
import AppSecurity from '@/components/ui/landing/feature/AppSecurity'
import Infrastructure from '@/components/ui/landing/feature/SecurityInfrastructure'
import Identity from '@/components/ui/landing/feature/IdentitySecurity'
import Cloud from '@/components/ui/landing/feature/SecurityCloud'
import Industries from '@/components/ui/landing/feature/SecurityIndustries'
import CallToAction from '@/components/ui/landing/cta/CallToAction4'

export default {
  components: {
    Strategy,
    DataPrivacy,
    AppSecurity,
    Infrastructure,
    Identity,
    Cloud,
    Industries,
    CallToAction
  },
  head: {
    title: {
      inner: 'Parfiniti',
      complement: 'Information Security Services'
    }
  }
}
</script>
